const getFieldName = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).replace(/_/g, ' ');
};

export const generateErrorResponse = (error) => {
  if (!error) return '';
  const errorsObg = error.errors.reduce((acc, item) => {
    Object.keys(item.detail).forEach((detailsItem) => {
      let value = item.detail[detailsItem];
      if (Array.isArray(value)) {
        value = value.reduce((strAcc, str) => {
          strAcc += `${str} `;
          return strAcc;
        }, '');
      }
      acc[getFieldName(detailsItem)] = value;
      return acc;
    });
    return acc;
  }, {});
  const resultString = Object.keys(errorsObg).reduce((acc, item) => {
    acc += `${item}: ${errorsObg[item]} \n`;
    return acc;
  }, '');
  return resultString;
};
