export { default as LOADER_ICON } from './loader.svg';
export { default as REMOVE_ICON } from './remove.svg';
export { default as DROP_ARROW } from './dropArrow.svg';
export { default as CT_LOGO } from './ct-logo.svg';
export { default as ARROW_RIGHT } from './arrow_right.svg';
export { default as BACK_ARROW } from './backArrow.svg';
export { default as CLOSE_MODAL_ICON } from './close-modal.svg';
export { default as EMAIL_NOTIFICATION_ICON } from './mail.svg';
export { default as INVALID_ICON } from './invalid.svg';
export { default as CHECKED_ICON } from './checked.svg';
export { default as NO_DATA } from './noData.svg';
export { default as EDIT_PEN } from './editPen.svg';
export { default as ADD_SURVEY } from './addSurvey.svg';
export { default as CHECK_ICON } from './checkIcon.svg';
export { default as ONLINE } from './online.svg';
export { default as OFFLINE } from './offline.svg';
export { default as CLOSE_MODAL_ICON_WHITE } from './close-white.png';
export { default as COPY_ICON } from './copyIcon.svg';
export { default as WHITE_CHECK } from './white-check.svg';
export { default as SYNC } from './sync.svg';
export { default as REMOVE_CIRCLE } from './remove-circle.svg';
export { default as RETURN } from './return.svg';
export { default as TABLE_EDIT_PENCIL } from './edit-pencil-table.svg';
export { default as VISITS } from './visits.svg';
export { default as USERS } from './users.svg';
export { default as PHARMACY } from './pharmacy.svg';
export { default as CARBON_POLICY } from './carbon_policy.svg';
export { default as INFO_ICON } from './info.svg';
export { default as INFO_DARK_ICON } from './infoDark.svg';
export { default as CONFIRM_DUPLICATES_ICON } from './confirmDuplicate.svg';
export { default as CONFIRM_SEPARATE_ICON } from './confirmSeparate.svg';
export { default as CONFIRM_HISTORIC_ICON } from './confirmHistoric.svg';
export { default as NOTIFICATION_BELL_ICON } from './notificationBell.svg';
export { default as FILE_DOWNLOAD_ICON } from './fileDownload.svg';
