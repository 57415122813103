import React, { memo } from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';

import * as Styled from './styles';

const MobileInput = ({ handleChange, value, error }) => (
  <Styled.FieldContainer error={error}>
    <Styled.Label htmlFor="phone" aria-label="phone">
      Phone*
    </Styled.Label>
    <PhoneInput
      inputProps={{ id: 'phone' }}
      value={value}
      onChange={handleChange}
      country={'us' || 1}
      onlyCountries={['us']}
      countryCodeEditable={false}
      masks={{
        us: '(...) ...-....',
        at: '(...) ...-....',
      }}
      placeholder="+1 (925) 123-1234"
    />
    <Styled.Error aria-label="error">{error && error}</Styled.Error>
  </Styled.FieldContainer>
);

MobileInput.propTypes = {
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
};

export default memo(MobileInput);
