import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isObjectLike } from 'lodash';
import { Section, ImageZoom, FileIconLink } from 'ui-kit';
import { AuthEntity, VisitEntity } from '_entities';
import { AccessControl, IsMassAccount } from 'modules';
import { removeHtmlTags, getDataFromInclude, formatDateTimeZone, getFileFormat } from 'utils/custom';
import moment from 'moment';
import { URLS, PERMISSIONS } from '_constants';
import { SURVEY_QUESTION_TYPE } from '../../constants';
import ViolationView from '../ViolationView';
import * as Styled from '../../styles';

const { getVisitData, getVisitInfo } = VisitEntity.selectors;
const { getTimeZone } = AuthEntity.selectors;

function VisitViewDetails({ noViolation }) {
  const { data: visitData, included: visitIncluded } = useSelector(getVisitData);
  const { sectionTitle } = useSelector(getVisitInfo);
  const timeZone = useSelector(getTimeZone);
  const visitAttributes = visitData && visitData.attributes;
  const questions = visitIncluded && getDataFromInclude(visitIncluded, 'Form')?.attributes.form_json.pages;
  const userCreatorId = visitData?.relationships.user.data?.id;
  const userModifiedById = visitData?.relationships.modified_by.data?.id;
  const userCreator = visitIncluded && getDataFromInclude(visitIncluded, 'User', userCreatorId);
  const userModifiedBy = visitIncluded && getDataFromInclude(visitIncluded, 'User', userModifiedById);
  const isMassAccount = IsMassAccount();
  const hasViolationInfo = isMassAccount && visitAttributes?.is_violation_open_status !== null && !noViolation;

  const getFormattedTime = useCallback((date) => {
    if (date) {
      return `${formatDateTimeZone(date, timeZone, 'LT')} ${timeZone} (UTC ${formatDateTimeZone(date, timeZone, 'Z')})`;
    }
    return '';
  }, []);

  const submittedDate = formatDateTimeZone(visitAttributes?.submitted, timeZone, 'L');
  const modifiedDate = formatDateTimeZone(visitAttributes?.modified, timeZone, 'L');

  const getTitle = useCallback((type, title) => {
    switch (type) {
      case SURVEY_QUESTION_TYPE.HTML:
      case SURVEY_QUESTION_TYPE.COMMENT: {
        return removeHtmlTags(title);
      }

      default:
        return title;
    }
  }, []);

  const getViewData = useMemo(() => {
    if (!visitData) return null;
    const EXCEPTIONS = ['any_notes', 'field_notes', 'thanks'];

    const getChoicesValues = (arr) => {
      if (!arr) return {};
      return arr.reduce((acc, item) => {
        acc[item.value] = item.text;
        return acc;
      }, {});
    };

    const getValue = ({ name, type, choices, fileType, inputType }) => {
      let result = '';
      // some fields in forms started form capital letter but in attributes is in lowercase
      let visitAttributesValue = visitAttributes[name] || visitAttributes[name.toLowerCase()];
      if (visitAttributesValue === undefined) {
        const visitAttributesMetadata = visitAttributes.metadata;
        let currentValue = visitAttributesMetadata[name] || visitAttributesMetadata[name.toLowerCase()];
        // Convert some values in capital letters in string 'FALSE', 'TRUE' into boolean
        const upperCaseValues = {
          TRUE: true,
          FALSE: false,
        };
        if (Object.keys(upperCaseValues).includes(currentValue)) {
          currentValue = upperCaseValues[currentValue];
        }
        visitAttributesValue = currentValue;
      }

      switch (type) {
        case SURVEY_QUESTION_TYPE.TEXT: {
          if (inputType === 'date' && visitAttributesValue) {
            result = moment(visitAttributesValue).format('MM/DD/YYYY');
            return result;
          }
          if (name === 'fda_letter_link') {
            result = <a href={visitAttributesValue}>{visitAttributesValue}</a>;
          } else {
            result = visitAttributesValue || 'No response';
          }
          return result;
        }

        case SURVEY_QUESTION_TYPE.DROPDOWN:
        case SURVEY_QUESTION_TYPE.RADIOGROUP: {
          const choicesObj = getChoicesValues(choices);
          result = choicesObj[visitAttributesValue] || 'No response';
          return result;
        }

        case SURVEY_QUESTION_TYPE.CHECKBOX: {
          const choicesObj = getChoicesValues(choices);

          if (Array.isArray(visitAttributesValue)) {
            visitAttributesValue.forEach((item, i) => {
              if (i > 0) {
                result += `, ${choicesObj[item]}`;
              } else {
                result += choicesObj[item];
              }
            });
          } else {
            result = choicesObj[visitAttributesValue] || 'No response';
          }
          return result;
        }

        case SURVEY_QUESTION_TYPE.HTML: {
          // In case if value is object it return string with capitalized keys and values separated by comma
          // @return {string}
          // @example {foo: 1, bar: 2, baz: 3}
          // return 'Foo: 1, Bar: 2, Baz: 3'
          if (isObjectLike(visitAttributesValue)) {
            const keys = Object.keys(visitAttributesValue);
            keys.forEach((key, i) => {
              const capitalizedKey = key.replace(/\w\S*/g, function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
              });
              result += `${i > 0 ? ', ' : ''}${capitalizedKey}: ${visitAttributesValue[key]}`;
            });
            break;
          }
          result = visitAttributesValue || 'No response';
          break;
        }

        case SURVEY_QUESTION_TYPE.FILE: {
          let filePath = visitAttributesValue?.file_path || visitAttributesValue;
          if (!filePath) {
            result = 'No response';
            break;
          }
          if (Array.isArray(filePath)) {
            filePath = visitAttributesValue[0].content;
          }
          if (fileType === 'image') {
            result = (
              <div>
                <ImageZoom zoom src={filePath} />
                <p>{visitAttributesValue?.file_name}</p>
              </div>
            );
          }
          if (fileType === 'pdf') {
            result = <FileIconLink value={visitAttributesValue} />;
          }
          break;
        }

        default:
          result = visitAttributesValue || 'No response';
          break;
      }
      return result;
    };

    return questions?.reduce((acc, item) => {
      item.questions?.forEach((innerItem) => {
        const { title, html, type, choices, name, inputType } = innerItem;
        if (EXCEPTIONS.includes(name) || html) return acc;
        acc.push({
          title: getTitle(type, title),
          value: getValue({ name, type, choices, inputType }),
        });
        return acc;
      });

      item.elements?.forEach((innerItem) => {
        const { name, type, title, html, choices, inputType } = innerItem;
        if (EXCEPTIONS.includes(name) || html) return acc;
        const value = getValue({ name, type, choices, fileType: getFileFormat(innerItem?.acceptedTypes), inputType });
        if (!value || html) return 'No response';
        acc.push({
          title: getTitle(type, title),
          value,
        });
        return acc;
      });

      return acc;
    }, []);
  }, [visitData]);

  const formatedSubmittedTime = getFormattedTime(visitAttributes?.submitted);
  const localSumbittedTime = formatedSubmittedTime.split(' ').slice(0, 2).join('');

  return (
    <>
      <Styled.RowWrapper>
        <Section title={sectionTitle}>
          <Styled.VerticalBorder />
          {getViewData && (
            <Styled.Dl>
              {getViewData.map(({ value, title }, i) => (
                <Styled.ItemWrapper key={`${title}${i}`}>
                  <Styled.Dt>{title}</Styled.Dt>
                  <Styled.Dd>{value || '-'}</Styled.Dd>
                  {i !== getViewData.length - 1 && <Styled.HorizontalBorder />}
                </Styled.ItemWrapper>
              ))}
            </Styled.Dl>
          )}
        </Section>
        <Section title="Submission information" isSubmission>
          <Styled.Dl isSubmission>
            <Styled.SubmissionItemWrapper>
              <Styled.Dt>Submitted date</Styled.Dt>
              <Styled.Dd>{submittedDate}</Styled.Dd>
            </Styled.SubmissionItemWrapper>
            <Styled.SubmissionItemWrapper>
              <Styled.Dt>Submitted time</Styled.Dt>
              <Styled.Dd>{localSumbittedTime === '12:00AM' ? ' - ' : formatedSubmittedTime}</Styled.Dd>
            </Styled.SubmissionItemWrapper>
            <Styled.SubmissionItemWrapper>
              <Styled.Dt>Completed by</Styled.Dt>
              <Styled.Dd>
                <Link to={`${URLS.users}/${userCreator?.id}`}>{userCreator?.attributes.email}</Link>
              </Styled.Dd>
            </Styled.SubmissionItemWrapper>
            <Styled.SubmissionItemWrapper>
              <Styled.Dt>Last modified user</Styled.Dt>
              <Styled.Dd>
                <Link to={`${URLS.users}/${userModifiedBy?.id}`}>{userModifiedBy?.attributes.email}</Link>
              </Styled.Dd>
            </Styled.SubmissionItemWrapper>
            <Styled.SubmissionItemWrapper>
              <Styled.Dt>Last modified date</Styled.Dt>
              <Styled.Dd>{modifiedDate}</Styled.Dd>
            </Styled.SubmissionItemWrapper>
          </Styled.Dl>
        </Section>
      </Styled.RowWrapper>
      {hasViolationInfo && visitAttributes && (
        <AccessControl permission={PERMISSIONS.VIEW_OTHER_VIOLATION_INFO}>
          <ViolationView />
        </AccessControl>
      )}
    </>
  );
}

VisitViewDetails.propTypes = {
  noViolation: PropTypes.bool,
};

VisitViewDetails.defaultProps = {
  noViolation: false,
};

export default VisitViewDetails;
