import styled from 'styled-components';
import { Button, Input } from 'ui-kit';
import { DEVICES } from '_constants';

export const Wrapper = styled.div`
  height: 100%;
  padding: 40px 16px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoginInput = styled(Input)`
  margin: 0 0 57px;

  @media ${DEVICES.tablet} {
    margin: 0 0 50px;
  }
`;

export const FormWrapper = styled.form`
  background: var(--white);
  max-width: 420px;
  width: 100%;
  padding: 35px 35px 25px;
  border: 1px solid var(--grey);
  border-radius: 12px;

  @media ${DEVICES.tablet} {
    border: none;
    padding: 0;
  }

  > a {
    display: inline-block;
    color: #5968b2;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.1;
    margin-bottom: 30px;
    text-decoration: underline;
  }
`;

export const Title = styled.h1`
  text-align: center;
  color: var(--dark);
  font-size: 26px;
  line-height: 1.1;
  margin-bottom: 50px;
  font-weight: 500;
`;

export const SubmitButton = styled(Button).attrs(() => ({
  type: 'submit',
}))`
  min-width: 147px;
  width: auto;
  max-width: 100%;
  margin: 0 auto;
  border-radius: 3px;
  font-weight: 500;
`;
