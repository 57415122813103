import styled, { css } from 'styled-components';
import { DEVICES } from '_constants';
import { deviceLess } from '_constants/DEVICES';
import Select from 'react-select';

export const RemoveOption = styled.div`
  background: transparent;
  padding: 0 4px;
  margin: 0;
  box-sizing: border-box;
  border: none;
  cursor: pointer;
`;

export const RemoveAllOptions = styled(RemoveOption)`
  svg {
    width: 20px;
    height: 20px;
  }
`;

export const DropdownIndicator = styled.div`
  padding: 0 4px;
  margin: 0;
  box-sizing: border-box;
`;

export const WrapperADAText = styled.span`
  display: none;
`;

export const Label = styled.span`
  display: inline-block;
  margin-bottom: 8px;
  font-size: 14px;
`;

export const ErrorWrapper = styled.div`
  position: relative;
  display: block;
`;

export const ErrorMessage = styled.span`
  font-size: 12px;
  line-height: 12px;
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  width: 100%;
  user-select: none;
  color: var(--danger);
`;

export const StyledSelect = styled(Select)`
  .BaseSelect__multi-value {
    max-width: 300px;
    overflow: auto;
  }
`;

export const HorizontalBorder = styled.div`
  @media ${deviceLess.laptop} {
    border-bottom: 1px solid #e0e0e0;
    position: absolute;
    height: 100%;
    left: -20px;
    width: calc(100% + 40px);
    z-index: 1;
    filter: opacity(0.4);
  }
`;

const columnsStyles = {
  three_columns: css`
    @media ${deviceLess.laptop} {
      display: flex;
      flex: 2;

      > span,
      > div {
        flex: 1;
      }
      ${ErrorMessage} {
        flex: 1;
      }
    }
  `,
  two_columns: css`
    @media ${deviceLess.laptop} {
      display: flex;

      > span {
        min-width: 400px;
      }
      > div {
        width: 100%;
        max-width: 1200px;
      }
      ${ErrorMessage} {
        width: 100%;
        max-width: 1200px;
      }
    }
  `,
  youth_columns: css`
    @media ${deviceLess.laptop} {
      display: flex;

      > span {
        min-width: 400px;
      }
      > div {
        width: 100%;
        max-width: 1200px;
      }
      ${ErrorMessage} {
        left: 0;
        max-width: 1200px;
      }
    }
  `,
  one_column_with_submission: css`
    @media ${deviceLess.laptop} {
      display: flex;

      > span {
        width: 100%;
        min-width: 200px;
        max-width: 50%;
        display: inline-block;
        word-wrap: break-word;
      }
      > div {
        padding-left: 60px;
        width: 100%;
        max-width: 1200px;
      }
      ${ErrorMessage} {
        padding-left: 60px;
        width: 100%;
        max-width: 1200px;
      }
    }
  `,
  one_column: css`
    @media ${deviceLess.laptop} {
      display: flex;

      > span {
        min-width: 200px;
      }

      > div {
        padding-left: 20px;
        width: 100%;
        max-width: 600px;
        min-width: 200px;
      }
      ${ErrorMessage} {
        padding-left: 20px;
        width: 100%;
        max-width: 600px;
        min-width: 200px;
      }
    }
  `,
  '': ``,
};

export const Wrapper = styled.label`
  ${({ pageColumns }) => pageColumns && columnsStyles[pageColumns]}
  }
  position: relative;
  padding-bottom: 20px;
  @media ${DEVICES.laptop} {
    display: flow;
      }

  .LoadedSelect,
  .BaseSelect {
    &__control {
      height: 100%;
      min-height: auto;
      border-radius: 0;
      
      @media ${DEVICES.laptop} {
      min-width: 100%;
      }
    }

    &__menu {
      z-index: 3;
      &-list {
        max-height: ${({ smallDrop }) => (smallDrop ? '115px' : '210px')};
        @media ${DEVICES.laptop} {
          max-height: 120px;
        }
      }
    }

    &__loading-indicator,
    &__indicator-separator {
      display: none;
    }

    &__placeholder {
      color: var(--dark);
    }
  }

  .error {
    .LoadedSelect,
    .BaseSelect {
      &__control {
        border-color: var(--danger) !important;
      }
    }
  }
`;
