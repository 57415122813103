export const saveToken = (token) => {
  sessionStorage.setItem('token', token);
};

export const removeToken = () => {
  sessionStorage.clear();
};

export const manageToken = (token = null) => {
  if (typeof token === 'string' && token.length > 1) {
    saveToken(token);
    return;
  }
  removeToken();
};

export const getToken = () => sessionStorage.getItem('token') || null;

export default {
  removeToken,
  manageToken,
  getToken,
};
