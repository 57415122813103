import React from 'react';
import { isNull } from 'lodash';
import { Section } from 'ui-kit';
import { URLS } from '_constants';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { formatDateTimeZone, getDataFromInclude } from 'utils/custom';
import * as Styled from '../../styles';

const CampaignDetails = ({ data, canSeePrivateStatus, included, timeZone }) => {
  const {
    private: privateCampaign,
    created,
    modified,
    retailers_count: retailersCount,
    retailers_active_count: retailersActiveCount,
    retailers_duplicate_count: retailersDuplicateCount,
    retailers_historic_count: retailerHistoricCount,
    retailers_assigned: retailersAssigned,
    retailers_not_assigned: retailersNotAssigned,
    visits_done: visitsDone,
    num_assign_submitted_violation: numberOfCompletedAssignmentsViolation,
  } = data?.attributes || {};

  const createdDate = formatDateTimeZone(created, timeZone, 'L');
  const modifiedDate = formatDateTimeZone(modified, timeZone, 'L');

  const accountId = data?.relationships?.account.data.id;
  const managerId = data?.relationships?.campaign_manager.data.id;
  const lastModifiedId = data?.relationships?.last_modified_by?.data?.id;

  const account = included && getDataFromInclude(included, 'Account', accountId)?.attributes?.name;
  const manager = included && getDataFromInclude(included, 'User', managerId)?.attributes?.email;
  const lastModifiedUser = included && getDataFromInclude(included, 'User', lastModifiedId)?.attributes?.email;

  return (
    <>
      <Styled.Inner>
        <Section title="Details">
          <Styled.VerticalBorderView />
          <Styled.Dl>
            {canSeePrivateStatus && (
              <Styled.ItemWrapper isDetails>
                <Styled.Dt>Is Private Campaign</Styled.Dt>
                <Styled.Dd>{privateCampaign ? 'Yes' : 'No'}</Styled.Dd>
                <Styled.HorizontalBorder />
              </Styled.ItemWrapper>
            )}
            {!isNull(retailersCount) && (
              <>
                <Styled.ItemWrapper isDetails>
                  <Styled.Dt>Initial Retailers Count</Styled.Dt>
                  <Styled.Dd>{retailersCount}</Styled.Dd>
                  <Styled.HorizontalBorder />
                </Styled.ItemWrapper>
                <Styled.ItemWrapper isDetails>
                  <Styled.Dt>Retailers Duplicates Count</Styled.Dt>
                  <Styled.Dd>{retailersDuplicateCount}</Styled.Dd>
                  <Styled.HorizontalBorder />
                </Styled.ItemWrapper>
                <Styled.ItemWrapper isDetails>
                  <Styled.Dt>Retailers Historic Count</Styled.Dt>
                  <Styled.Dd>{retailerHistoricCount}</Styled.Dd>
                  <Styled.HorizontalBorder />
                </Styled.ItemWrapper>
                <Styled.ItemWrapper isDetails>
                  <Styled.Dt>Retailers Count</Styled.Dt>
                  <Styled.Dd>{retailersActiveCount}</Styled.Dd>
                  <Styled.HorizontalBorder />
                </Styled.ItemWrapper>
                <Styled.ItemWrapper isDetails>
                  <Styled.Dt>Retailers already assigned</Styled.Dt>
                  <Styled.Dd>{retailersAssigned}</Styled.Dd>
                  <Styled.HorizontalBorder />
                </Styled.ItemWrapper>
                <Styled.ItemWrapper isDetails>
                  <Styled.Dt>Retailers left to assign</Styled.Dt>
                  <Styled.Dd>{retailersNotAssigned}</Styled.Dd>
                </Styled.ItemWrapper>
                <Styled.ItemWrapper isDetails>
                  <Styled.Dt>Total visits done in campaign</Styled.Dt>
                  <Styled.Dd>{visitsDone}</Styled.Dd>
                </Styled.ItemWrapper>
                <Styled.ItemWrapper isDetails>
                  <Styled.Dt>Revisits needed (retailers with a violation)</Styled.Dt>
                  <Styled.Dd>{numberOfCompletedAssignmentsViolation}</Styled.Dd>
                </Styled.ItemWrapper>
              </>
            )}
          </Styled.Dl>
        </Section>
        <Section title="Relationships">
          <Styled.VerticalBorderView />
          <Styled.Dl>
            <Styled.ItemWrapper>
              <Styled.Dt>Account</Styled.Dt>
              <Styled.Dd>{account}</Styled.Dd>
              <Styled.HorizontalBorder />
            </Styled.ItemWrapper>
            <Styled.ItemWrapper>
              <Styled.Dt>Campaign manager</Styled.Dt>
              <Styled.Dd>
                <Link to={`${URLS.users}/${managerId}`}>{manager}</Link>
              </Styled.Dd>
            </Styled.ItemWrapper>
          </Styled.Dl>
        </Section>
        <Section title="Created & Modified">
          <Styled.VerticalBorderView />
          <Styled.Dl>
            <Styled.ItemWrapper>
              <Styled.Dt>Created</Styled.Dt>
              <Styled.Dd>{createdDate}</Styled.Dd>
              <Styled.HorizontalBorder />
            </Styled.ItemWrapper>
            <Styled.ItemWrapper>
              <Styled.Dt>Last modified user</Styled.Dt>
              <Styled.Dd>
                {lastModifiedId ? <Link to={`${URLS.users}/${lastModifiedId}`}>{lastModifiedUser}</Link> : '-'}
              </Styled.Dd>
              <Styled.HorizontalBorder />
            </Styled.ItemWrapper>
            <Styled.ItemWrapper>
              <Styled.Dt>Last modified date</Styled.Dt>
              <Styled.Dd>{modifiedDate}</Styled.Dd>
            </Styled.ItemWrapper>
          </Styled.Dl>
        </Section>
      </Styled.Inner>
    </>
  );
};

CampaignDetails.propTypes = {
  data: PropTypes.object,
  included: PropTypes.array,
  timeZone: PropTypes.string,
  canSeePrivateStatus: PropTypes.bool,
};

CampaignDetails.defaultProps = {
  data: {},
  included: [],
  timeZone: '',
  canSeePrivateStatus: false,
};

export default CampaignDetails;
