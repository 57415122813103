export const getDataCollectionFromInclude = (included, type, field, idList = null) => {
  if (idList) {
    return included.reduce((acc, item) => {
      if (item.type === type && idList.includes(item.id)) {
        acc.push(item.attributes[field]);
      }
      return acc;
    }, []);
  }
  return included.reduce((acc, item) => {
    if (item.type === type) {
      acc.push(item.attributes[field]);
    }
    return acc;
  }, []);
};
